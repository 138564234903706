import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import naive from "naive-ui";

// Import vue-toastification
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

// import Main css
import "./assets/css/main.css";

// Import AOS assets
import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();

createApp(App)
  .use(AOS)
  .use(Toast)
  .use(naive)
  .use(store)
  .use(router)
  .mount("#app");
