import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "web",
    component: () => import("@/pages/web.vue"),
  },
  {
    path: "/cart",
    name: "cart",
    component: () => import("@/pages/cartPage.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/pages/loginPage.vue"),
  },
  {
    path: "/medications",
    name: "medications",
    component: () => import("@/pages/allMedications.vue"),
  },
  {
    path: "/history",
    name: "history",
    component: () => import("@/pages/historyPage.vue"),
  },
  {
    path: "/history/:id",
    name: "orderDetails",
    component: () => import("@/pages/ordersDetails.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth",
      };
    } else {
      return { left: 0, top: 0 };
    }
  },
});

export default router;
