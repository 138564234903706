<template>
  <footer>
    <p>Powered by <a href="https://ivas.rw" target="_blank">Innovative VAS</a> &copy; {{ currentYear }}</p>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      currentYear: "",
    };
  },
  mounted() {
    const myDate = new Date();
    const currentYear = myDate.getFullYear();
    this.currentYear = currentYear;
  },
};
</script>

<style lang="scss" scoped>


footer {
    width: 100%;
    text-align: center;
    padding: 30px 7%;
    background-color: var(--primary-color);
    color: white;


    p {
        a {
            color: white;
            font-size: 16px;
        }
    }
}

</style>
