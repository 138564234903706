<template>
  <Loader v-if="showLoader" />
  <div class="main" v-else>
    <Navbar />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Footer from "./components/Footer.vue";
import Loader from "./components/Loader.vue";
import Navbar from "./components/Navbar.vue";
import axios from "@/helpers/axios";

export default {
  data() {
    return {
      showLoader: true,
    };
  },
  components: {
    Navbar,
    Footer,
    Loader,
  },

  created() {
    // Check if there are medicaition saved to cart in the localstorage
    if (localStorage.getItem("cart") !== null) {
      let MedicationInLocalStorage = JSON.parse(localStorage.getItem("cart"));
      this.reSetCart(MedicationInLocalStorage);
    }
  },
  mounted() {
    this.fetchData();

    if (localStorage.getItem("user")) {
      const user = JSON.parse(localStorage.getItem("user"));

      if (user) {
        // console.log(user);
        this.$store.commit("SET_USER", user);
      }
    } else {
      console.log("You are not logged in");
    }
  },
  methods: {
    ...mapActions({
      setPharmacyDetails: "setPharmacyDetails",
      setHomePasgeDetails: "setHomePasgeDetails",
      setAboutDetails: "setAboutDetails",
      setContactDetails: "setContactDetails",
      setMedicationDetials: "setMedicationDetials",
      setInsuranceDetails: "setInsuranceDetails",
      setServiceFee: "setServiceFee",
      reSetCart: "reSetCart",
    }),
    // Check for the pharmacy Information
    async fetchData() {
      // Get the domain name from the URL
      const domainName = String(window.location.origin);

      try {
        // Fetch from the API

        if (location.hostname == "localhost") {
          var pharmacyResponse = await axios.get(
            `api/pharmacy-details?domain_name=http://ubumenyi.rw/`
          );
        } else {
          var pharmacyResponse = await axios.get(
            `api/pharmacy-details?domain_name=${domainName}/`
          );
        }

        const insuranceResponse = await axios.get("api/insurances");

        const serviceResponse = await axios.get("api/service-fees");

        // Change the service fee to a good lookig object
        const modifiedServiceFee = {
          pickup: parseInt(serviceResponse.data.data[0].pick_up).toFixed(0),
          delivery: parseInt(serviceResponse.data.data[0].delivery).toFixed(0),
        };

        // Change insurance Response to a good looking array
        const modifiedInsuranceArray = insuranceResponse.data.data.map(
          (insurance) => {
            return {
              insurance_id: insurance.id,
              insurance_name: insurance.insurance_name,
            };
          }
        );

        // Send the recieved response data to vuex
        this.setPharmacyDetails(pharmacyResponse.data.data);
        this.setHomePasgeDetails(pharmacyResponse.data.data.pharmacy);

        localStorage.setItem(
          "pharmacy_name",
          pharmacyResponse.data.data.pharmacy.name
        );

        document.title = pharmacyResponse.data.data.pharmacy.name;

        this.setAboutDetails(pharmacyResponse.data.data.pharmacy);
        this.setContactDetails(pharmacyResponse.data.data.pharmacy);
        this.setMedicationDetials(pharmacyResponse.data.data.medication);
        this.setInsuranceDetails(modifiedInsuranceArray);
        this.setServiceFee(modifiedServiceFee);

        // Hide the preloader of the web once all data have been Fetched!

        if (pharmacyResponse) {
          this.showLoader = false;
        }
      } catch (error) {}
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=SUSE:wght@100..800&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#app {
  font-family: "SUSE", sans-serif;
  font-optical-sizing: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --primary-color: #2ea4be;
  --primary-color-hover: #2a98b1;
  --gray-color: #a0a3a9;
  --gray-color-light: rgb(230, 230, 230);
  --red-color: #ed5e89;
  --red-color-hover: rgb(237, 94, 137, 0.4);
}

.main {
  width: 100%;
  min-height: 100vh;
  // overflow-y: scroll;
}
</style>
