import axios from "axios";

let ApiUrl = "http://188.166.16.179/";

const instance = axios.create({
  baseURL: ApiUrl,
});

instance.interceptors.request.use(
  (config) => { 
    const token = localStorage.getItem("tk");

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;
