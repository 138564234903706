<template>
    <div class="loader-container">
      <div class="spinner"></div>
     <p> Please wait ...</p>
    </div>
  </template>
  
  <script>
  export default {
    setup() {
      return {};
    },
  };
  </script>
  
  <style lang="scss" scoped>
  
  .loader-container {
      width: 100%;
      height: 100vh;
      display: flex;
      justify-content:center; 
      align-items: center; 
      flex-direction: column;
      gap: 20px;
  
  
      .spinner {
          width: 30px; 
          height: 30px;
          border: 2px solid var(--gray-color-light);
          border-top:2px solid var(--primary-color); 
          border-left: 2px solid var(--primary-color); 
          border-radius: 50px; 
          animation: spin 1s linear infinite
      }
  }
  
  @keyframes spin {
      from {
          transform: rotate(0deg);
      }
  
      to {
          transform: rotate(360deg)
      }
  }
  
  
  </style>
  