import { createStore } from "vuex";
import axios from "@/helpers/axios";
import { useToast } from "vue-toastification";
const toast = useToast();

export default createStore({
  state: {
    pharmacy_details: {
      pharmacy_id: null,
      pharmacy_name: null,
      pharmacy_phone: null,
      logo: null,
      branches: null,
    },
    pages_details: {
      home_page: {
        bg_image: null,
        home_description: null,
      },
      medication_page: {
        futured_medications: null,
      },
      about_page: {
        bg_image: null,
        about_description: null,
      },
      contact_page: {
        bg_image: null,
      },
    },
    insurances_details: null,
    service_fee: null,
    cart: [],
    user: null,
  },
  getters: {
    // Get only the required data from the state
    getPharmacyDetails(state) {
      return state.pharmacy_details;
    },
    getHomePageData(state) {
      return state.pages_details.home_page;
    },
    getMedicationData(state) {
      return state.pages_details.medication_page.futured_medications;
    },
    getAboutPageData(state) {
      return state.pages_details.about_page;
    },
    getContactPageData(state) {
      return state.pages_details.contact_page;
    },
    getInsurances(state) {
      return state.insurances_details.map((insurance) => {
        return {
          label: insurance.insurance_name,
          value: insurance.insurance_id,
        };
      });
    },
    getBranches(state) {
      return state.pharmacy_details.branches;
    },
    getCart(state) {
      return state.cart;
    },
    getServiceFee(state) {
      return state.service_fee;
    },
    getSubTotal(state) {
      return state.cart.reduce((total, item) => {
        return total + item.unit_price * item.quantity;
      }, 0);
    },
    getUserInfo(state) {
      return state.user;
    },
  },
  mutations: {
    SET_PHARMACY_DETAILS(state, payload) {
      state.pharmacy_details.pharmacy_id = payload.pharmacy.id;
      state.pharmacy_details.pharmacy_name = payload.pharmacy.name;
      state.pharmacy_details.pharmacy_phone = payload.pharmacy.phone;
      state.pharmacy_details.logo = payload.pharmacy.logo;
      state.pharmacy_details.branches = payload.branches.map((branch) => {
        return {
          branch_id: branch.id,
          branch_name: branch.branch_name,
          branch_address: branch.link,
          working_hours: branch.workingHours.map((holder) => {
            return {
              day: holder.day,
              open: holder.opening_time,
              close: holder.closing_time,
            };
          }),
        };
      });
    },
    SET_HOME_PAGE_DETAILS(state, payload) {
      state.pages_details.home_page.bg_image = payload.background_image;
      state.pages_details.home_page.home_description = payload.title;
    },
    SET_ABOUT_DETAILS(state, payload) {
      state.pages_details.about_page.bg_image = payload.about_us_image;
      state.pages_details.about_page.about_description =
        payload.about_us_description;
    },
    SET_CONTACT_DETAILS(state, payload) {
      state.pages_details.contact_page.bg_image = payload.contact_us_image;
    },
    SET_MEDICATION_DETAILS(state, payload) {
      state.pages_details.medication_page.futured_medications = payload;
    },
    SET_INSURANCES_DETAILS(state, payload) {
      state.insurances_details = payload;
    },
    SET_SERVICE_FEE(state, payload) {
      state.service_fee = payload;
    },
    SET_CART(state, payload) {
      const existingItem = state.cart.find(
        (cartItem) => cartItem.medication_id === payload.medication_id
      );
      if (existingItem) {
        toast.error("Medicine is already in the cart");
      } else {
        // Logic to add cart to localstorage

        if (localStorage.getItem("cart") == null) {
          const cart = [];
          cart.push(payload);
          localStorage.setItem("cart", JSON.stringify(cart));
        } else {
          // First get the existing cart
          const cart = JSON.parse(localStorage.getItem("cart"));
          cart.push(payload);
          localStorage.setItem("cart", JSON.stringify(cart));
        }
        state.cart.push(payload);
      }
    },
    REMOVE_MEDICATION(state, payload) {
      let cart = JSON.parse(localStorage.getItem("cart"));
      cart = cart.filter((medicine) => medicine.medication_id !== payload);
      localStorage.setItem("cart", JSON.stringify(cart));

      state.cart = state.cart.filter(
        (medicine) => medicine.medication_id !== payload
      );
    },
    SET_USER(state, payload) {
      state.user = {
        id: String(payload.id),
        first_name: payload.first_name,
        last_name: payload.last_name,
        phone: payload.phone.slice(2),
        isLoggedIn: true,
      };

      localStorage.setItem("user", JSON.stringify(state.user));
    },
    SET_TOKEN(state, token) {
      state.token = token;
      localStorage.setItem("tk", token);
    },
    REMOVE_USER(state) {
      state.user = null;
      toast.success("You are logged out!");
    },
    RE_SET_CART(state, payload) {
      payload.forEach((medicine) => {
        state.cart.push(medicine);
      });
    },
    EMPTY_CART(state) {
      state.cart = [];
    },
  },
  actions: {
    setPharmacyDetails({ commit }, payload) {
      commit("SET_PHARMACY_DETAILS", payload);
    },
    setHomePasgeDetails({ commit }, payload) {
      commit("SET_HOME_PAGE_DETAILS", payload);
    },
    setAboutDetails({ commit }, payload) {
      commit("SET_ABOUT_DETAILS", payload);
    },
    setContactDetails({ commit }, payload) {
      commit("SET_CONTACT_DETAILS", payload);
    },
    setMedicationDetials({ commit }, payload) {
      commit("SET_MEDICATION_DETAILS", payload);
    },
    setInsuranceDetails({ commit }, payload) {
      commit("SET_INSURANCES_DETAILS", payload);
    },
    setServiceFee({ commit }, payload) {
      commit("SET_SERVICE_FEE", payload);
    },
    setCart({ commit }, payload) {
      commit("SET_CART", payload);
    },
    removeMedication({ commit }, payload) {
      commit("REMOVE_MEDICATION", payload);
    },
    async loginUser({ commit }, credentials) {
      try {
        const response = await axios.post("api/login", credentials);
        const { user, token } = response.data;

        // console.log(user);
        commit("SET_USER", user);
        commit("SET_TOKEN", token);

        return response;
      } catch (error) {
        toast.error(error.response.data.message);
      }
    },
    logoutUser({ commit }) {
      commit("REMOVE_USER");
    },
    reSetCart({ commit }, payload) {
      commit("RE_SET_CART", payload);
    },
    emptyCart({ commit }) {
      commit("EMPTY_CART");
    },
  },

  modules: {},
});
