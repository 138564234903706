<template>
  <header>
    <div class="upper">
      <div class="logo-container">
        <img :src="pharmacyDetails.logo" width="50px" alt="" />
        <h2>{{ pharmacyDetails.pharmacy_name }}</h2>
      </div>
    </div>

    <div class="lower">
      <nav>
        <ul>
          <router-link to="/#home-page" class="active-link">
            <li>Home</li>
          </router-link>
          <router-link to="/#medications">
            <li>Medication</li>
          </router-link>
          <router-link to="/#about">
            <li>About</li>
          </router-link>
          <router-link to="/#contactUs">
            <li>Contact us</li>
          </router-link>
        </ul>
      </nav>

      <div class="features-container">
        <ul>
          <router-link :to="{ name: 'cart' }">
            <li>
              <svg
                width="30"
                height="30"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.2914 28.3207H33.8286C34.4807 28.3207 35.05 27.785 35.05 27.065C35.05 26.345 34.4807 25.8093 33.8286 25.8093H14.5757C13.6214 25.8093 13.0357 25.1393 12.885 24.1185L12.6171 22.3607H33.8614C36.3057 22.3607 37.5614 20.8535 37.9129 18.46L39.2521 9.60356C39.2881 9.3933 39.3105 9.18096 39.3193 8.96784C39.3193 8.16427 38.7164 7.61142 37.7957 7.61142H10.4579L10.1393 5.4857C9.97215 4.19642 9.50358 3.54285 7.79644 3.54285H1.9193C1.25001 3.54285 0.680725 4.12999 0.680725 4.79999C0.680725 5.4857 1.25001 6.07213 1.92001 6.07213H7.57787L10.2564 24.4535C10.6086 26.8307 11.8636 28.3207 14.2914 28.3207ZM36.4729 10.1393L35.2857 18.1586C35.1514 19.1964 34.5993 19.8328 33.6114 19.8328L12.25 19.8493L10.8264 10.1393H36.4729ZM15.6143 36.4571C15.9713 36.4601 16.3253 36.3919 16.6557 36.2567C16.986 36.1214 17.2862 35.9218 17.5386 35.6693C17.7911 35.4169 17.9907 35.1167 18.126 34.7863C18.2612 34.456 18.3294 34.102 18.3264 33.745C18.328 33.3884 18.259 33.035 18.1233 32.7052C17.9875 32.3754 17.7878 32.0758 17.5356 31.8236C17.2835 31.5714 16.9839 31.3717 16.6541 31.236C16.3243 31.1003 15.9709 31.0312 15.6143 31.0328C14.0914 31.0328 12.8857 32.2385 12.8857 33.745C12.8857 35.2685 14.0914 36.4571 15.6143 36.4571ZM31.2671 36.4571C32.7907 36.4571 33.9957 35.2685 33.9957 33.745C33.9957 32.2378 32.7907 31.0328 31.2671 31.0328C29.7607 31.0328 28.5386 32.2385 28.5386 33.745C28.5386 35.2685 29.7607 36.4571 31.2671 36.4571Z"
                  fill="#A0A3A9"
                />
              </svg>

              <div class="totalItems">{{ cart.length }}</div>
            </li>
          </router-link>

          <n-popselect
            v-model:value="selectedMenuItem"
            :options="usersDropDown"
            v-if="UserInfo"
          >
            <a href="#" class="userIcon">
              <li>
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20 16.6667C23.6819 16.6667 26.6666 13.6819 26.6666 10C26.6666 6.31814 23.6819 3.33337 20 3.33337C16.3181 3.33337 13.3333 6.31814 13.3333 10C13.3333 13.6819 16.3181 16.6667 20 16.6667Z"
                    stroke="#A0A3A9"
                    stroke-width="2.5"
                  />
                  <path
                    d="M33.33 30.0001C33.3322 29.7267 33.3333 29.449 33.3333 29.1667C33.3333 25.0251 27.3633 21.6667 20 21.6667C12.6367 21.6667 6.66666 25.0251 6.66666 29.1667C6.66666 33.3084 6.66666 36.6667 20 36.6667C23.7183 36.6667 26.4 36.4051 28.3333 35.9384"
                    stroke="#A0A3A9"
                    stroke-width="2.5"
                    stroke-linecap="round"
                  />
                </svg>
              </li>
            </a>
          </n-popselect>
        </ul>
      </div>
      <div class="button-container" v-if="!UserInfo">
        <button @click="this.$router.push({ name: 'login' })">Login</button>
      </div>
    </div>
  </header>
</template>

<script>
import logo from "@/assets/images/logo.png";
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      logo,
      selectedMenuItem: null,
      usersDropDown: [
        {
          label: "My Purchase",
          value: "history",
        },
        {
          label: "Logout",
          value: "Logout",
        },
      ],
    };
  },
  watch: {
    selectedMenuItem(newValue) {
      if (newValue == "Logout") {
        localStorage.removeItem("tk");
        localStorage.removeItem("user");
        this.logoutUser();
        this.selectedMenuItem = null;
      } else if (newValue == "history") {
        this.$router.push({ name: "history" });
        this.selectedMenuItem = null;
      }
    },
  },
  computed: {
    ...mapGetters({
      pharmacyDetails: "getPharmacyDetails",
      UserInfo: "getUserInfo",
      cart: "getCart",
    }),
  },
  methods: {
    ...mapActions({
      logoutUser: "logoutUser",
    }),
  },
};
</script>

<style lang="scss" scoped>
header {
  width: 100%;
  background-color: white;
  padding: 0px 7%;
  padding-bottom: 0;
  border-bottom: 1px solid rgb(241, 241, 241);
  display: flex;
  justify-content: space-between;

  gap: 12px;
  //   position: relative;
  //   top: 0;
  z-index: 300;

  .upper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo-container {
      display: flex;
      align-items: center;
      gap: 12px;
      color: var(--primary-color);
      padding: 20px 0;
    }
  }

  .lower {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    gap: 25px;
    nav {
      ul {
        display: flex;
        align-items: center;
        list-style-type: none;

        a {
          text-decoration: none;
          color: var(--gray-color);
          font-size: 16px;

          &:hover {
            color: var(--primary-color);
          }

          li {
            padding: 10px 20px;
            border-bottom: 2px solid transparent;
          }
        }

        .active-link {
          color: var(--primary-color);

          // li {
          //   // border-bottom: 2px solid var(--primary-color);
          // }
        }
      }
    }

    .features-container {
      ul {
        display: flex;
        list-style-type: none;
        gap: 20px;

        li {
          position: relative;

          .totalItems {
            position: absolute;
            top: -3px;
            right: -7px;
            width: 18px;
            height: 18px;
            background-color: var(--primary-color-hover);
            color: white;
            border-radius: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
          }
        }

        a {
          &:not(.userIcon):hover {
            li {
              svg {
                path {
                  fill: var(--primary-color);
                }
              }
            }
          }
        }

        .userIcon {
          &:hover {
            li {
              svg {
                path {
                  stroke: var(--primary-color);
                }
              }
            }
          }
        }
      }
    }

    .button-container {
      button {
        width: 200px;
        height: 50px;
        background-color: var(--primary-color);
        color: white;
        font-size: 16px;
        border-radius: 6px;
        border: none;
        cursor: pointer;
        transition: 200ms ease;

        &:hover {
          background-color: var(--primary-color-hover);
        }
      }
    }
  }
}



@media (max-width: 768px) { 


  header {


    .lower {
      display: none;
      nav {
      }
    }
  }


 }
</style>
